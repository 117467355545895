import { Component } from "react";

import projectPreviewImage from "../../media/project_hodlend.png";
import brandIdentityImage from "../../media/hodlend/brandIdentity_Hodlend.png";
import brandIdentityImageMobile from "../../media/hodlend/brandIdentity_Hodlend-mobile.png";
import teaserImage from "../../media/hodlend/website_teaser_hodlend.gif";

import closeIconDefault from "../../media/close.svg";
import closeIconMobile from "../../media/close-mobile.svg";
import arrowUp from "../../media/arrow_2.svg";

export default class Hodlend extends Component{

    constructor(props){
        super(props);
        this.slideUpDefaultIcon = <div id="slideUpButton" className="slideUpIcon mobile-version" onClick={this.slideUp}><img src={arrowUp} alt=""/></div>;
        this.state={
            closeIcon : null,
            slideUpIcon : null,
            brandIdentiy : null
        }
    }

    previewLink = () => {
        window.open('https://hodlend.com/', '_blank');
    }

    sendEmail = () => {
        window.location.href = "mailto:hello@giuliadilonardo.com";
    }

    slideUp = () => {
        var popupProject = document.getElementById("popupProject");
        popupProject.scrollTo(0, 0);
    }

    scrollFunction = () => {
        var popupProject = document.getElementById("popupProject");
        if (popupProject.scrollTop > 100) {
            this.setState({slideUpIcon:this.slideUpDefaultIcon});
        } else {
            this.setState({slideUpIcon:null});
        }
    }

    updateImages = () => {
        if(window.innerWidth<=767){
            //MOBILE DEVICE
            this.setState({closeIcon:closeIconMobile});
            this.setState({brandIdentiy:brandIdentityImageMobile});
        }else{
            this.setState({closeIcon:closeIconDefault});
            this.setState({brandIdentiy:brandIdentityImage});
        }
    };
    componentDidMount() {
        /* setting image types (mobile/tablet-desktop) for first render */
        this.updateImages();        
        window.addEventListener('resize', this.updateImages);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateImages);
    }

    render(){
        return(
            <div className="projectPopup" id="popupProject" onScroll={this.scrollFunction}>
                { this.state.slideUpIcon }
                <div className="layoutHeader not-selectable">
                    <div className="layoutRestrictionsHeader">
                        <div className="header">
                            <img className="closeIcon" src={this.state.closeIcon} onClick={this.props.closeProject} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection1 not-selectable firstSection">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns">
                            <p className="title anno">OTTOBRE 2020</p>
                            <p className="title">TEAM</p>
                            <p className="subtitle">Alexandra D'Auria<span className="tablet-version-inline">, Giulia Di Lonardo e Francesco Forgione</span></p>
                            <p className="subtitle desktop-version">Giulia Di Lonardo</p>
                            <p className="subtitle end desktop-version">Francesco Forgione</p>
                            <p className="title pfinali">PRODOTTI FINALI</p>
                            <p className="subtitle">UI Design<span className="tablet-version-inline">, UX Design, Visual Design</span></p>
                            <p className="subtitle desktop-version">UX Design</p>
                            <p className="subtitle end desktop-version">Visual Design</p>
                        </div>
                        <div className="content">
                            <p className="projectTitle">Hodlend - Un sito di exchange criptovalute</p>
                            <p className="description">Hodlend è un servizio <span className="boldText">di exchange criptovalute</span>. É stata creata una brand image, un sito teaser, un sito vetrina ed una dashboard per la gestione delle criptovalute.</p>
                            <img className="projectPreviewImage" src={projectPreviewImage} alt="Hodlend - Un sito di exchange criptovalute"/>
                            <p className="titles">Brand identity</p>
                            <p className="descriptions">Per il logo abbiamo pensato di <span className="boldText">esaltare il mondo del trading online</span>, fatto di oscillazioni.<br/><br/>L’<span className="boldText">H di Hodlend va a rappresentare gli andamenti attraverso due grafici in crescita</span> (uno verticale e uno orizzontale) che vogliono essere di buon auspicio a chi si avvicina a Hodlend.<br/><br/>Per rendere il brand alla portata di tutti abbiamo optato per un <span className="boldText">font</span> che desse un <span className="boldText">tono caldo</span> e <span className="boldText">amichevole</span>. Aggiungendo poi il colore lo abbiamo reso <span className="boldText">giovane</span> e <span className="boldText">fresco</span>. Il blu vuole trasmettere sicurezza e fiducia mentre l’arancione spingere all’azione, trasmettendo forza e coraggio. I colori blu e arancione vanno a caratterizzare anche il <span className="boldText">sito teaser</span>, il <span className="boldText">sito vetrina</span>, la <span className="boldText">dashboard</span> e le <span className="boldText">illustrazioni</span>.</p>
                        </div>
                        <div className="paddingColumns desktop-version"/>
                    </div>
                </div>
                <div className="layoutSection2-hodlend not-selectable">
                    <div className="layoutRestrictions">
                        <div className="content">
                            <img className="brandIdentityImage" src={this.state.brandIdentiy} alt="Hodlend - BrandIdentity"/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection3-hodlend not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <p className="titles">Teaser</p>
                            <p className="descriptions"><span className="boldText">In attesa dell’uscita del sito ufficiale</span>, abbiamo creato un teaser che mostrasse <span className="boldText">i lati migliori di Hodlend</span>. In questa fase è stato fondamentale l’utilizzo delle illustrazioni che oltre a rendere il teaser più accattivante, ha aggiunto valore ai contenuti.</p>
                            <img className="teaserImage" src={teaserImage} alt="Hodlend - Uikit"/>
                            <p className="titles">Prodotto finale</p>
                            <p className="descriptions">Se vuoi saperne di più su questo progetto, <span className="linkPreview" onClick={this.sendEmail}><span className="boldText projectContactMe">contattami</span> ⟶</span></p>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
            </div>
        );
    }

}