import { Component } from "react";

import projectPreviewImage from "../../media/project_portaloutdoor.png";
import brandIdentityImage from "../../media/portaloutdoor/brandIdentity_Portaloutdoor.png";
import brandIdentityImageMobile from "../../media/portaloutdoor/brandIdentity_Portaloutdoor-mobile.png";
import uikitImage from "../../media/portaloutdoor/uikit.png";
import uikitImageMobile from "../../media/portaloutdoor/uikit-mobile.png";
import uikitImagep2 from "../../media/portaloutdoor/uikit-p2.png";
import uikitImageMobilep2 from "../../media/portaloutdoor/uikit-p2-mobile.png";
import uikitImagep3 from "../../media/portaloutdoor/uikit-p3.png";
import uikitImageMobilep3 from "../../media/portaloutdoor/uikit-p3-mobile.png";

import closeIconDefault from "../../media/close.svg";
import closeIconMobile from "../../media/close-mobile.svg";
import arrowUp from "../../media/arrow_2.svg";

export default class Portaloutdoor extends Component{

    constructor(props){
        super(props);
        this.slideUpDefaultIcon = <div id="slideUpButton" className="slideUpIcon mobile-version" onClick={this.slideUp}><img src={arrowUp} alt=""/></div>;
        this.state={
            closeIcon : null,
            slideUpIcon : null,
            brandIdentiy : null,
            uikit : null,
            uikitp2 : null,
            uikitp3 : null
        }
    }

    previewLink = () => {
        window.open('https://xd.adobe.com/view/e4864c80-1788-4933-99bb-4d916b3f4b80-b1d5/?fullscreen', '_blank');
    }

    slideUp = () => {
        var popupProject = document.getElementById("popupProject");
        popupProject.scrollTo(0, 0);
    }

    scrollFunction = () => {
        var popupProject = document.getElementById("popupProject");
        if (popupProject.scrollTop > 100) {
            this.setState({slideUpIcon:this.slideUpDefaultIcon});
        } else {
            this.setState({slideUpIcon:null});
        }
    }

    updateImages = () => {
        if(window.innerWidth<=767){
            //MOBILE DEVICE
            this.setState({closeIcon:closeIconMobile});
            this.setState({brandIdentiy:brandIdentityImageMobile});
            this.setState({uikit:uikitImageMobile});
            this.setState({uikitp2:uikitImageMobilep2});
            this.setState({uikitp3:uikitImageMobilep3});
        }else{
            this.setState({closeIcon:closeIconDefault});
            this.setState({brandIdentiy:brandIdentityImage});
            this.setState({uikit:uikitImage});
            this.setState({uikitp2:uikitImagep2});
            this.setState({uikitp3:uikitImagep3});
        }
    };
    componentDidMount() {
        /* setting image types (mobile/tablet-desktop) for first render */
        this.updateImages();        
        window.addEventListener('resize', this.updateImages);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateImages);
    }

    render(){
        return(
            <div className="projectPopup" id="popupProject" onScroll={this.scrollFunction}>
                { this.state.slideUpIcon }
                <div className="layoutHeader not-selectable">
                    <div className="layoutRestrictionsHeader">
                        <div className="header">
                            <img className="closeIcon" src={this.state.closeIcon} onClick={this.props.closeProject} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection1 not-selectable firstSection">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns">
                            <p className="title anno">MARZO 2021</p>
                            <p className="title">TEAM</p>
                            <p className="subtitle end">Giulia Di Lonardo</p>
                            <p className="title pfinali">PRODOTTI FINALI</p>
                            <p className="subtitle">UI Design<span className="tablet-version-inline">, UX Design, Visual Design</span></p>
                            <p className="subtitle desktop-version">UX Design</p>
                            <p className="subtitle end desktop-version">Visual Design</p>
                        </div>
                        <div className="content">
                            <p className="projectTitle">Portaloutdoor - Database di impianti pubblicitari</p>
                            <p className="description">Portaloutdoor è un servizio offerto dall’agenzia Gdloutdoor che ricerca, pianifica e acquista <span className="boldText">impianti pubblicitari in tutta Italia</span>. Questo servizio, ti permette di <span className="boldText">individuare spazi pubblicitari</span> di qualità presenti <span className="boldText">su tutto il territorio italiano</span>.</p>
                            <img className="projectPreviewImage" src={projectPreviewImage} alt="Portaloutdoor - Database di impianti pubblicitari"/>
                            <p className="titles">Brand identity</p>
                            <p className="descriptions">Il logo Portaloutdoor è composto da forme che ricordano la <span className="boldText">lettera P</span> ed inoltre è la rappresentazione di un <span className="boldText">portale che si apre</span> e <span className="boldText">mostra ciò che c’è al suo interno</span>. Il <span className="boldText">colore</span> utilizzato vuole sottolineare il campo della <span className="boldText">creatività</span> rendendolo <span className="boldText">elegante</span> ma allo stesso tempo <span className="boldText">giovane</span>.</p>
                        </div>
                        <div className="paddingColumns desktop-version"/>
                    </div>
                </div>
                <div className="layoutSection2-portaloutdoor not-selectable">
                    <div className="layoutRestrictions">
                        <div className="content">
                            <img className="brandIdentityImage" src={this.state.brandIdentiy} alt="Hodlend - BrandIdentity"/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection3-portaloutdoor not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <p className="titles">UI kit</p>
                            <img className="uikitImage" src={this.state.uikit} alt="Ollin - Uikit"/>
                            <p className="titles">Filtri: caso di studio</p>
                            <p className="descriptions">In questo progetto è stato fondamentale la <span className="boldText">costruzione di un filtro</span> che potesse contenere <span className="boldText">i vari formati</span> (piccoli, medi e maxi) <span className="boldText">degli impianti pubblicitari</span>. Nella <span className="boldText">fase iniziale</span> ho voluto provare ad inserire le <span className="boldText">tre tipologie</span> in <span className="boldText">tre differenti filtri</span>.</p>
                            <img className="uikitImagep2" src={this.state.uikitp2} alt="Ollin - Uikit"/>
                            <p className="descriptions">Successivamente ho provato una <span className="boldText">seconda versione</span> che si è rivelata essere <span className="boldText">più intuitiva</span>. In questo caso il <span className="boldText">filtro si riduce ad uno solo</span>, diminuendo così la probabilità di fraintendere l’azione da svolgere. I formati sono inseriti in un <span className="boldText">unico dropdown</span>, con la possibilità di <span className="boldText">selezione multipla</span>. Scegliendo i filtri desiderati sarà possibile applicarli ed avere i risultati.</p>
                            <img className="uikitImagep3" src={this.state.uikitp3} alt="Ollin - Uikit"/>
                            <p className="descriptions"><span className="boldText">Mettersi nei panni dell’utente</span> è di fondamentale importanza per poter <span className="boldText">creare un prodotto funzionante</span> e che non dia nessun tipo di problema.</p>
                            <p className="titles">Prodotto finale</p>
                            <p className="descriptions end">Se sei interessato al progetto, puoi dare un occhio al prototipo.<br/><span className="linkPreview" onClick={this.previewLink}><span className="boldText projectPreviewLink">Visualizza prototipo</span>  ⟶</span></p>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
            </div>
        );
    }

}