import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import Homepage from './App';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>

    <Router>
      <Switch>
        <Route path="/:query">
          <Homepage />
        </Route>
        <Route path="/">
          <Homepage />
        </Route>
      </Switch>
    </Router>

    
  </React.StrictMode>,
  document.getElementById('root')
);
