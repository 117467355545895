import { Component } from "react";

import projectPreviewImage from "../../media/project_ollin.jpg";
import brandIdentityImage from "../../media/ollin/brandIdentity.png";
import brandIdentityImageMobile from "../../media/ollin/brandIdentity-mobile.png";
import uikitImage from "../../media/ollin/uikit.png";
import uikitImageMobile from "../../media/ollin/uikit-mobile.png";
import mappaAttenzione from "../../media/ollin/mappa_attenzione.png";
import punteggioChiarezza from "../../media/ollin/punteggio_chiarezza.png";

import closeIconDefault from "../../media/close.svg";
import closeIconMobile from "../../media/close-mobile.svg";
import arrowUp from "../../media/arrow_2.svg";

import Slider from "./Slider";

export default class Ollin extends Component{

    constructor(props){
        super(props);
        this.slideUpDefaultIcon = <div id="slideUpButton" className="slideUpIcon mobile-version" onClick={this.slideUp}><img src={arrowUp} alt=""/></div>;
        this.state={
            closeIcon : null,
            slideUpIcon : null,
            brandIdentiy : null,
            uikit : null
        }
    }

    previewLink = () => {
        window.open('https://projects.invisionapp.com/prototype/Ollin-un-app-per-tutti-i-mezzi-di-trasporto-ck7sapk4f001sar01ueyo63a1/play/8a94cba3', '_blank');
    }

    slideUp = () => {
        var popupProject = document.getElementById("popupProject");
        popupProject.scrollTo(0, 0);
    }

    scrollFunction = () => {
        var popupProject = document.getElementById("popupProject");
        if (popupProject.scrollTop > 100) {
            this.setState({slideUpIcon:this.slideUpDefaultIcon});
        } else {
            this.setState({slideUpIcon:null});
        }
    }

    updateImages = () => {
        if(window.innerWidth<=767){
            //MOBILE DEVICE
            this.setState({closeIcon:closeIconMobile});
            this.setState({brandIdentiy:brandIdentityImageMobile});
            this.setState({uikit:uikitImageMobile});
        }else{
            this.setState({closeIcon:closeIconDefault});
            this.setState({brandIdentiy:brandIdentityImage});
            this.setState({uikit:uikitImage});
        }
    };
    componentDidMount() {
        /* setting image types (mobile/tablet-desktop) for first render */
        this.updateImages(); 
        window.addEventListener('resize', this.updateImages);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateImages);
    }

    render(){
        return(
            <div className="projectPopup ollin-project-page" id="popupProject" onScroll={this.scrollFunction}>
                { this.state.slideUpIcon }
                <div className="layoutHeader not-selectable">
                    <div className="layoutRestrictionsHeader">
                        <div className="header">
                            <img className="closeIcon" src={this.state.closeIcon} onClick={this.props.closeProject} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection1 not-selectable firstSection">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns">
                            <p className="title anno">FEBBRAIO 2020</p>
                            <p className="title">TEAM</p>
                            <p className="subtitle">Giulia Di Lonardo<span className="tablet-version-inline"> e Francesco Forgione</span></p>
                            <p className="subtitle end desktop-version">Francesco Forgione</p>
                            <p className="title pfinali">PRODOTTI FINALI</p>
                            <p className="subtitle">UI Design<span className="tablet-version-inline">, UX Design, Visual Design</span></p>
                            <p className="subtitle desktop-version">UX Design</p>
                            <p className="subtitle end desktop-version">Visual Design</p>
                        </div>
                        <div className="content">
                            <p className="projectTitle">Ollin - Un'app per tutti i mezzi di trasporto</p>
                            <p className="description">Applicazione <span className="boldText">che raggruppa tutti i mezzi di trasporto pubblico e sharing in un unico posto</span> con l’obiettivo di gestire in app abbonamenti ai servizi che utilizzi e molto altro.</p>
                            <img className="projectPreviewImage" src={projectPreviewImage} alt="Ollin - Un'app per tutti i mezzi di trasporto"/>
                            <p className="titles">Brand identity</p>
                            <p className="descriptions">Il nome Ollin nasce dall’italianizzazione di ‘<span className="boldText">all in</span>’, cioè tutto compreso. Il logo è creato da <span className="boldText">forme geometriche</span> che ricordano il movimento, mentre il font utilizzato in app è un <span className="boldText">Roboto</span>, scelto per la sua chiarezza e semplicità. I colori verde e blu del logo, sono i colori di settore, mentre il corallo va a creare contrasto e vivacità, infatti sarà utilizzato nelle illustrazioni.</p>
                        </div>
                        <div className="paddingColumns desktop-version"/>
                    </div>
                </div>
                <div className="layoutSection2-ollin not-selectable">
                    <div className="layoutRestrictions">
                        <div className="content">
                            <img className="brandIdentityImage" src={this.state.brandIdentiy} alt="Ollin - BrandIdentity"/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection3-ollin not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <p className="titles">UI kit</p>
                            <img className="uikitImage" src={this.state.uikit} alt="Ollin - Uikit"/>
                            <p className="titles">Perché nasce Ollin</p>
                            <p className="descriptions">Questa applicazione è stata pensata per <span className="boldText">ridurre lo stress dei pendolari</span> causato da problemi tecnici dei sistemi e delle tecnologie attualmente in uso. Come primo obiettivo è stato fondamentale rendere la navigazione in app <span className="boldText">accessibile</span> a chiunque, in modo <span className="boldText">semplice</span> e <span className="boldText">chiaro</span>, con un linguaggio <span className="boldText">amichevole e che trasmetta fiducia</span>.<br/><br/>La semplicità d'uso, <span className="boldText">invoglierà le persone ad utilizzare i trasporti pubblici</span>, contribuendo così alla <span className="boldText">diminuzione dell’inquinamento</span> ambientale.<br/><br/>Di seguito i <span className="boldText">problemi</span> e le <span className="boldText">soluzioni</span> che abbiamo affrontato:</p>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
                <div className="layoutSection4-ollin not-selectable">
                    <div className="layoutRestrictions">
                        <div className="content">
                            <Slider/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection5-ollin not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <p className="titles">Test di usabilità</p>
                            <p className="descriptions">Questa fase è stata fondamentale per <span className="boldText">perfezionare il lavoro e renderlo accessibile a chiunque</span>.<br/><br/>Seguendo le linee guida del WCAG (Web Content Accessibility Guidelines), abbiamo regolato i <span className="boldText">contrasti</span> fondamentali per la visualizzazione di un contenuto web.<br/><br/>Successivamente abbiamo effettuato un <span className="boldText">test di usabilità per verificare che la nostra UX fosse compresa correttamente</span>, utilizzando Visualeys, un sito che con un’intelligenza artificiale, simula l’occhio umano e prevede modelli di comportamento degli utenti attraverso due parametri: <span className="boldText">mappa dell’attenzione</span> e <span className="boldText">punteggio della chiarezza</span>.</p>
                            <div className="appAccessibilityPreview">
                                <div className="column">
                                    <img className="image1" src={mappaAttenzione} alt="Ollin - Preview - Mappa attenzione"/>
                                    <div className="imageTitle n1">MAPPA DELL'ATTENZIONE</div>
                                    <div className="imageDescription n1">Zone rosse dove ricade maggiormente l'attenzione</div>
                                </div>
                                <div className="column">
                                    <img className="image2" src={punteggioChiarezza} alt="Ollin - Preview - Punteggio chiarezza"/>
                                    <div className="imageTitle n2">PUNTEGGIO DELLA CHIAREZZA</div>
                                    <div className="imageDescription n2">Questa schermata è chiara all'85%</div>
                                </div>
                            </div>
                            <p className="titles">Prodotto finale</p>
                            <p className="descriptions end">Se sei interessato al progetto, c'è molto altro da scoprire.<br/><span className="linkPreview" onClick={this.previewLink}><span className="boldText projectPreviewLink">Visualizza prototipo</span>  ⟶</span></p>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
            </div>
        );
    }

}