import { Component } from "react";

import "./SliderStyle.css";

import imgSlider1 from "../../media/ollin/perdere-abbonamento-slider1.png";
import imgSlider2 from "../../media/ollin/perdere-tempo-slider2.png";
import imgSlider3 from "../../media/ollin/rinnovare-richiede-tempo-slider3.png";
import imgSlider4 from "../../media/ollin/sharing-in-app-slider4.png";
import imgSlider5 from "../../media/ollin/mancanza-di-informazioni-slider5.png";

export default class Slider extends Component{

    componentDidMount(){
        var elem = document.querySelector('.gallery');
        new window.Flickity( elem, {
            contain: true,
            setGallerySize: false,
            cellAlign: 'left',
            arrowShape: 'M 100 46.851562 L 12.058594 46.851562 L 31.878906 27.027344 L 27.425781 22.574219 L 0 50 L 27.425781 77.425781 L 31.878906 72.972656 L 12.058594 53.148438 L 100 53.148438 Z M 100 46.851562'
        });
    }

    render(){
        return(
            <div className="gallery js-flickity" data-flickity='{ "adaptiveHeight": true }'>
                <div className="gallery-cell">
                    <img className="imagePreview" src={imgSlider1} alt="Ollin - Preview - Perdere l'abbonamento"/>
                    <div className="gallery-cell-text">
                        <p className="title">Perdere l'abbonamento</p>
                        <p className="description">Può capitare di <span className="boldText">perdere il proprio abbonamento</span> ai mezzi di trasporto. Con Ollin, questo problema scompare, perché viene generata una <span className="boldText">tessera digitale</span> che comprende tutti i tuoi abbonamenti.</p>
                    </div>
                </div>
                <div className="gallery-cell">
                    <img className="imagePreview" src={imgSlider2} alt="Ollin - Preview - Perdere tempo"/>
                    <div className="gallery-cell-text">
                        <p className="title">Perdere tempo</p>
                        <p className="description">Avere abbonamenti, biglietti e carnet fisici può diventare un problema. Perdiamo tempo nel cercarli e convalidarli. Con Ollin, lo si può fare direttamente dallo smartphone <span className="boldText">attivando l’NFC</span>.</p>
                    </div>
                </div>
                <div className="gallery-cell">
                    <img className="imagePreview" src={imgSlider3} alt="Ollin - Preview - Rinnovare richiede tempo"/>
                    <div className="gallery-cell-text">
                        <p className="title">Rinnovare richiede tempo</p>
                        <p className="description">Rinnovare un abbonamento risulta un processo lungo per via delle <span className="boldText">biglietterie rotte</span>, <span className="boldText">code agli sportelli</span> e malfunzionamenti nel <span className="boldText">rinnovo online</span>. Con Ollin puoi <span className="boldText">rinnovarlo automaticamente</span>.</p>
                    </div>
                </div>
                <div className="gallery-cell">
                    <img className="imagePreview" src={imgSlider4} alt="Ollin - Preview - Sharing in app"/>
                    <div className="gallery-cell-text">
                        <p className="title">Sharing in app</p>
                        <p className="description">Gli sharing ormai sono diventati fondamentali per girare in città, ma non esiste <span className="boldText">un’unica app</span> che contenga tutti gli <span className="boldText">sharing</span> e <span className="boldText">gli altri mezzi di trasporto</span>. In Ollin puoi trovare anche questa funzione.</p>
                    </div>
                </div>
                <div className="gallery-cell">
                    <img className="imagePreview" src={imgSlider5} alt="Ollin - Preview - Mancanza di informazioni"/>
                    <div className="gallery-cell-text">
                        <p className="title">Mancanza di informazioni</p>
                        <p className="description">La mancanza di informazione quando si viaggia può risultare spiacevole. Registrando il tuo tragitto in Ollin, puoi avere <span className="boldText">aggiornamenti in tempo reale</span> e vedere gli <span className="boldText">orari dei tuoi mezzi</span>.</p>
                    </div>
                </div>
            </div>
        );
    }

}