import { Component } from "react";
import { withRouter } from "react-router";
import './Progetti/popupProjects.css';

//Progetti
import Ollin from './Progetti/Ollin/Ollin';
import TwentyOnePilots from './Progetti/TwentyOnePilots/TwentyOnePilots';
import Hodlend from './Progetti/Hodlend/Hodlend';
import Portaloutdoor from './Progetti/Portaloutdoor/Portaloutdoor';

import projectImagePortaloutdoor from './media/project_portaloutdoor.png';
import projectImageHodlend from './media/project_hodlend.png';
import projectImageOllin from './media/project_ollin.jpg';
import projectImage21Pilots from './media/project_21pilots.png';


class App extends Component {

  constructor(props){
    super(props);
    this.state={
      popupProject:''
    }
    this.query = null;
    if(this.props.match.params.query){
      this.query = this.props.match.params.query.toLowerCase();
    }
  }

  sendEmail = () => {
    window.location.href = "mailto:hello@giuliadilonardo.com";
  }

  openProject(i){
    switch(i){
      case "ollin":
        this.props.history.replace("/ollin");
        document.body.style.overflowY = "hidden";
        this.setState({popupProject:<Ollin closeProject={this.closeProject}/>});
        break;
      case "hodlend":
        this.props.history.replace("/hodlend");
        document.body.style.overflowY = "hidden";
        this.setState({popupProject:<Hodlend closeProject={this.closeProject}/>});
        break;
      case "twentyonepilots":
        this.props.history.replace("/twentyonepilots");
        document.body.style.overflowY = "hidden";
        this.setState({popupProject:<TwentyOnePilots closeProject={this.closeProject}/>});
        break;
      case "portaloutdoor":
        this.props.history.replace("/portaloutdoor");
        document.body.style.overflowY = "hidden";
        this.setState({popupProject:<Portaloutdoor closeProject={this.closeProject}/>});
        break;
      default:
        break;
    }
  }

  closeProject = () => {
    document.body.style.overflowY = "auto";
    this.props.history.replace('/');
    this.setState({popupProject:""});
  }

  componentDidMount(){
    if(this.query && this.query!=null){
      this.props.history.replace(this.query);
      if(this.query === "ollin" || this.query === "hodlend" || this.query === "twentyonepilots" || this.query === "portaloutdoor"){
        this.openProject(this.query);
      }else{
        this.props.history.replace("/");
      }      
    }
  }

  render(){
    return(
      <div className="Homepage">
        <div className="introductionTitle">Giulia Di Lonardo - Digital Product Designer</div>
        <div className="introductionSection not-selectable">
          <div className="left">
            <div className="introductionText">
              <h1>Ciao,<br className="mobile-version"/> sono Giulia</h1>
              <p className="p1">Digital Product Designer italiana, appassionata di UI/UX, <br className="desktop-version"/>web design, branding, fotografia e illustrazione. <br className="desktop-version"/>Ho un debole per la cucina, il cucito, realizzare oggetti <br className="desktop-version"/> con le mie mani e adoro cantare.</p>
              <p className="p2">Dalle mie esperienze ho imparato che il lavoro di gruppo è <br className="desktop-version"/><br className="tablet-version"/>vitale per la realizzazione di un buon progetto, ma anche per raggiungere un obiettivo nella nostra vita.<br className="desktop-version"/><span className="desktop-version"><span className="email-link" onClick={this.sendEmail}><span className="underline">Contattami subito</span></span> ⟶</span><span className="mobile-version"><span className="underlinedIntro" onClick={this.sendEmail}>Contattami subito</span> ⟶</span><br className="tablet-version"/><span className="tablet-version"><span className="underlinedIntro" onClick={this.sendEmail}>Contattami subito</span> ⟶</span></p>
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="projectSection not-selectable">
          <div className="project n1" onClick={()=>this.openProject("portaloutdoor")}>
            <div className="projectImage"><img src={projectImagePortaloutdoor} alt="Portaloutdoor - Database di impianti pubblicitari"/></div>
            <p className="projectTitle"><span>Portaloutdoor - Database di impianti pubblicitari</span></p>
            <p className="projectInfo">2021 - brand, web design</p>
          </div>
          <div className="project n2" onClick={()=>this.openProject("hodlend")}>
            <div className="projectImage"><img src={projectImageHodlend} alt="Hodlend - Un sito di exchange criptovalute"/></div>
            <p className="projectTitle"><span>Hodlend - Un sito di exchange criptovalute</span></p>
            <p className="projectInfo">2021 - brand, illustrazione, web design</p>
          </div>
          <div className="project n3" onClick={()=>this.openProject("ollin")}>
            <div className="projectImage"><img src={projectImageOllin} alt="Ollin - Un'app per tutti i mezzi di trasporto"/></div>
            <p className="projectTitle"><span>Ollin - Un'app per tutti i mezzi di trasporto</span></p>
            <p className="projectInfo">2020 - app design</p>
          </div>
          <div className="project n4" onClick={()=>this.openProject("twentyonepilots")}>
            <div className="projectImage"><img src={projectImage21Pilots} alt="Twenty One Pilots - Landing page"/></div>
            <p className="projectTitle"><span>Twenty One Pilots - Landing page</span></p>
            <p className="projectInfo">2019 - web desgin</p>
          </div>
        </div>
        <div className="linkSection not-selectable">
          <p className="links first"><a className="underline" onClick={this.sendEmail}>email</a></p>
          <p className="links"><a href="https://www.behance.net/giuliadilonardo1" target="_blank" rel="noreferrer" className="underline">behance</a></p>
          <p className="links"><a href="https://www.linkedin.com/in/giuliadilonardo/" target="_blank" rel="noreferrer" className="underline">linkedin</a></p>
          <p className="links"><a href="/cv_giulia_di_lonardo_2022.pdf" target="_blank" className="underline">curriculum</a></p>
        </div>
        <div className="footerSection not-selectable">
          <p>Ti auguro una buona giornata /<br className="mobile-version"/> serata / notte&nbsp;&nbsp;<i className="fas fa-heart"></i></p>
          <p className="copyright">© 2021 Giulia Di Lonardo. Sito a cura di Andrea Di Lonardo</p>
        </div>
        <div className="contactButtonSection mobile-version not-selectable" onClick={this.sendEmail}>
          <div className="contactButton">
            <p>CONTATTAMI SUBITO</p>
            <div className="arrow"/>
          </div>
        </div>

        {/*POPUP PROJECT SECTION*/}
        {this.state.popupProject}

      </div>
    )
  }

}

export default withRouter(App);
