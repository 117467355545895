import { Component } from "react";

import projectPreviewImage from "../../media/project_21pilots.png";
import moodboardImage from "../../media/21pilots/moodboard21.png";
import moodboardImageMobile from "../../media/21pilots/moodboard21-mobile.png";
import prototipoDesktop from "../../media/21pilots/twentyonepilots_prototipo_desktop.png";
import prototipoTablet from "../../media/21pilots/twentyonepilots_prototipo_tablet.png";
import prototipoMobile from "../../media/21pilots/twentyonepilots_prototipo_mobile.png";

import closeIconDefault from "../../media/close.svg";
import closeIconMobile from "../../media/close-mobile.svg";
import arrowUp from "../../media/arrow_2.svg";

export default class TwentyOnePilots extends Component{

    constructor(props){
        super(props);
        this.slideUpDefaultIcon = <div id="slideUpButton" className="slideUpIcon mobile-version" onClick={this.slideUp}><img src={arrowUp} alt=""/></div>;
        //0 = modeDesktop, 1 = modeTablet, 2 = modeMobile
        this.state={
            selectedMode:["selected","",""],
            previewImage:prototipoDesktop,
            typePrototipo:"desktop",
            closeIcon : null,
            slideUpIcon : null,
            moodboard: null
        }
    }

    changeMode(mode){
        switch(mode){
            case "desktop":
                this.setState({selectedMode:["selected","",""], previewImage:prototipoDesktop, typePrototipo:"desktop"});
                break;
            case "tablet":
                this.setState({selectedMode:["","selected",""], previewImage:prototipoTablet, typePrototipo:"tablet"});
                break;
            case "mobile":
                this.setState({selectedMode:["","","selected"], previewImage:prototipoMobile, typePrototipo:"mobile"});
                break;
            default:
                break;
        }
    }

    previewLink = () => {
        window.open('https://projects.invisionapp.com/prototype/Ollin-un-app-per-tutti-i-mezzi-di-trasporto-ck7sapk4f001sar01ueyo63a1/play/8a94cba3', '_blank');
    }

    slideUp = () => {
        var popupProject = document.getElementById("popupProject");
        popupProject.scrollTo(0, 0);
    }

    scrollFunction = () => {
        var popupProject = document.getElementById("popupProject");
        if (popupProject.scrollTop > 100) {
            this.setState({slideUpIcon:this.slideUpDefaultIcon});
        } else {
            this.setState({slideUpIcon:null});
        }
    }

    updateImages = () => {
        if(window.innerWidth<=767){
            //MOBILE DEVICE
            this.setState({closeIcon:closeIconMobile});
            this.setState({moodboard:moodboardImageMobile});
        }else{
            this.setState({closeIcon:closeIconDefault});
            this.setState({moodboard:moodboardImage});
        }
    };
    componentDidMount() {
        /* setting image types (mobile/tablet-desktop) for first render */
        this.updateImages();
        window.addEventListener('resize', this.updateImages);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateImages);
    }

    render(){
        return(
            <div className="projectPopup" id="popupProject" onScroll={this.scrollFunction}>
                { this.state.slideUpIcon }
                <div className="layoutHeader not-selectable">
                    <div className="layoutRestrictionsHeader">
                        <div className="header">
                            <img className="closeIcon" src={this.state.closeIcon} onClick={this.props.closeProject} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection1 not-selectable firstSection">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns">
                            <p className="title anno">DICEMBRE 2019</p>
                            <p className="title">TEAM</p>
                            <p className="subtitle end">Giulia Di Lonardo</p>
                            <p className="title pfinali">PRODOTTI FINALI</p>
                            <p className="subtitle">UI Design<span className="tablet-version-inline">, UX Design</span></p>
                            <p className="subtitle end desktop-version">UX Design</p>
                        </div>
                        <div className="content">
                            <p className="projectTitle">Twenty One Pilots - Landing Page</p>
                            <p className="description">Per questo progetto accademico del corso di Web Design, ho realizzato una <span className="boldText">landing page responsive</span> per promuovere l’uscita del nuovo album musicale del gruppo Twenty One Pilots. L’obiettivo era far <span className="boldText">conoscere</span>, <span className="boldText">apprezzare</span> ed in fine <span className="boldText">acquistare</span> il prodotto. </p>
                            <img className="projectPreviewImage" src={projectPreviewImage} alt="Twenty One Pilots - Preview Landing page"/>
                            <p className="titles">Stile utilizzato</p>
                            <p className="descriptions">La grafica che ho voluto realizzare rispecchia lo stile del nuovo album. I colori utilizzati sono il giallo e il nero, mentre le <span className="boldText">call to action</span> diventano degli <span className="boldText">adesivi</span>.</p>
                        </div>
                        <div className="paddingColumns desktop-version"/>
                    </div>
                </div>
                <div className="layoutSection2-21pilots not-selectable">
                    <div className="layoutRestrictions">
                        <div className="content">
                            <img className="moodboardImage" src={this.state.moodboard} alt="Twenty One Pilots - Moodboard"/>
                        </div>
                    </div>
                </div>
                <div className="layoutSection3-21pilots not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <p className="titles">Cosa mostrare</p>
                            <p className="descriptions">È stato molto <span className="boldText">importante scegliere cosa inserire nella landing page</span>. Oltre a mostrare che è attualmente <span className="boldText">disponibile</span> e <span className="boldText">acquistabile</span> il nuovo album, hai la possibilità di scoprire i <span className="boldText">brani musicali presenti</span>, che puoi ascoltare sulle varie piattaforme, il <span className="boldText">merchandising</span>, che puoi <span className="boldText">acquistare dallo shop</span>, le <span className="boldText">date disponibili del tour</span> e <span className="boldText">ricevere aggiornamenti</span>.</p>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
                <div className="layoutSection4-21pilots not-selectable">
                    <div className="layoutRestrictions">
                        <div className="paddingColumns"/>
                        <div className="content">
                            <div className="selectorLayout">
                                <div className="titles">Prototipo</div>
                                <div className="modeSelector"><div className={"mode "+this.state.selectedMode[0]} onClick={()=>this.changeMode("desktop")}>Desktop</div><div className={"mode "+this.state.selectedMode[1]} onClick={()=>this.changeMode("tablet")}>Tablet</div><div className={"mode end "+this.state.selectedMode[2]} onClick={()=>this.changeMode("mobile")}>Mobile</div></div>
                            </div>
                            <div className={"prototipo "+this.state.typePrototipo}>
                                <img src={this.state.previewImage} alt="Twenty One Pilots - Prototype"/>
                            </div>
                        </div>
                        <div className="paddingColumns"/>
                    </div>
                </div>
            </div>
        );
    }

}